<template>
  <v-container
    style="padding: 0; margin: auto; border-radius: 5px; background:white;"
  >
    <v-form ref="entryForm" @submit.prevent="submit">
      <v-card
        flat
        style="
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
        >
          <v-toolbar-title class="overline"
            ><p style="font-size: 20px; margin: 0; font-weight: bold">
              Formulir Pengeluaran Arsip Dokumen
            </p>
          </v-toolbar-title>
        </div>
        <div>
          <v-btn
            type="submit"
            tile
            color="black"
            small
            class="ma-2"
            style="color: white"
          >
            Submit
          </v-btn>

          <v-btn
            small
            tile
            color="black"
            class="ma-2"
            style="color: white"
            @click="close"
          >
            Close
          </v-btn>
        </div>
      </v-card>

      <v-row v-if="isAdmin" style="padding: 15px">
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                PIC
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.pic"
                clearable
                outlined
                item-text="name"
                item-id="id"
                hide-details
                hide-no-data
                return-object
                label="Type Name"
                :items="itemss"
                :loading="loading"
                :search-input.sync="search"
                style="margin: 0"
                :rules="picNameRules"
                dense
              ></v-autocomplete>
              <!-- <v-text-field
                v-model="form.pic"
                label="Type Name"
                dense
                outlined
                style="margin: 0"
                :rules="picNameRules"
              /> -->
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Email
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.email"
                :rules="emailRules"
                dense
                outlined
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Plant
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.plant"
                :items="getDropdownPlant"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0"
                readonly
                :rules="plantRules"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Department
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.department"
                :items="getDropdownDepartment"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0"
                readonly
                :rules="departmentRules"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                @change="cekDocStatus"
                v-if="getDocId.id === null"
                v-model="form.doc"
                :items="getDropdownArchive"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0;"
                :rules="docRules"
              />
              <v-text-field
                v-else
                dense
                outlined
                :value="getDocId.name"
                style="margin: 0; "
              />
              <p
                :style="
                  `margin:0;font-size:12px;position:relative;top:-25px;color:${
                    docStatus.msg !== 'Document available' ? 'red' : 'green'
                  };`
                "
              >
                {{ docStatus.msg }}
              </p>
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Jumlah
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                type="number"
                v-model="form.qty"
                dense
                outlined
                style="margin: 0"
                :rules="qtyRules"
              />
            </v-col>
          </div>
        </v-col>
        <!-- <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                No. Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form"
                dense
                outlined
                style="margin: 0"
                :rules="plantIdRules"
              />
            </v-col>
          </div>
        </v-col> -->

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Pengeluaran
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0"
              ><v-text-field
                v-model="form.loan_start_date"
                outlined
                dense
                type="date"
                name="date"
                step="1"
                :rules="releaseDateRules"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Satuan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.qty_uom"
                :items="[
                  { id: 1, name: 'Pcs' },
                  { id: 2, name: 'Lbr' },
                  { id: 3, name: 'Book' },
                  { id: 4, name: 'Set' }
                ]"
                item-text="name"
                item-value="id"
                return-object
                dense
                outlined
                style="margin: 0"
                :rules="unitRules"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Alasan Dikeluarkan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-textarea
                v-model="form.loan_purpose"
                dense
                auto-grow
                outlined
                :rules="reasonRules"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'releaseform',
  data() {
    return {
      eSanqua: buildType.apiURL('esanqua'),
      download: buildType.apiURL('download'),
      build: process.env.VUE_APP_BUILD_TYPE,
      isAdmin: null,
      // menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 31 : 29,
      menu_key: process.env.VUE_APP_BUILD_TYPE === 'development' ? 29 : 29,
      application: {
        id: 14,
        name: 'eArchive'
      },
      permission: {},
      dialog: false,
      form: {},
      loading: false,
      select: null,
      search: null,
      itemss: [],
      new: [],

      docRules: [],
      picNameRules: [],
      plantRules: [],
      departmentRules: [],
      emailRules: [],
      qtyRules: [],
      unitRules: [],
      releaseDateRules: [],
      reasonRules: [],
      docStatus: {
        code: '',
        msg: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getDropdownDepartment',
      'getDropdownUnit',
      'getDropdownPlant',
      'getDropdownArchive',
      'getDropdownEmployee',
      'getDocId',
      'getUserProfile'
    ])
  },
  created() {
    const a = this.build === 'development' ? '19' : '20'
    const b = this.build === 'development' ? '20' : '21'
    console.log(this.getUserProfile)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            console.log(result)
            this.permission = result.data
            this.setPermission(result.data)
            if (arr[i].id === '1' || arr[i].id === a || arr[i].id === b) {
              this.isAdmin = true
              this.initDropdown('admin')
              this.initForm()
            } else {
              this.isAdmin = false
              this.initDropdown('public_user')
              this.initFormUser()
            }
          })
          .catch(error => {
            if (error) {
              this.isAdmin = null
              this.permission = {
                read_perm: 0
              }
              this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.isAdmin = null
        this.permission = {
          read_perm: 0
        }
      }
    }
  },
  // updated() {
  //   this.initDropdown();
  //   this.initForm();
  // },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    'form.pic'() {
      if (this.isAdmin) {
        if (this.form.pic !== null) {
          this.form.employee_id = this.form.pic.id
          this.form.employee_nik = this.form.pic.nik
          this.form.employee_name = this.form.pic.name
          this.form.plant = {
            id: this.form.pic.company_id,
            name: this.form.pic.company_name
          }
          this.form.department = {
            id: this.form.pic.department_id,
            name: this.form.pic.department_name
          }
          this.form.email = this.form.pic.email
        } else {
          this.form.employee_id = ''
          this.form.employee_nik = ''
          this.form.employee_name = ''
          this.form.plant = ''
          this.form.department = ''
          this.form.email = ''
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'dropdownDepartment',
      'dropdownUnit',
      'dropdownPlant',
      'dropdownArchive',
      'dropdownEmployee',
      'menu_access'
    ]),
    ...mapMutations(['setPermission', 'setLoaningDocumentName']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.docRules = []
      this.picNameRules = []
      this.plantRules = []
      this.departmentRules = []
      this.emailRules = []
      this.qtyRules = []
      this.unitRules = []
      this.releaseDateRules = []
      this.reasonRules = []
      this.form = {}
      this.docStatus.code = ''
      this.docStatus.msg = ''
    },
    clickOutside() {
      this.close()
    },
    close() {
      this.setLoaningDocumentName(['', null, null])
      this.$router.push('/archive-management/document-release/')
    },
    // loadForm() {
    //   console.log(this.param)
    // },
    initForm() {
      this.form = {
        doc: '',
        employee_id: '',
        employee_nik: '',
        employee_name: '',
        pic: '',
        plant: '',
        // plant_name: "",
        department: '',
        // department_name: "",
        email: '',
        qty: '',
        qty_uom: '',
        loan_start_date: '',
        loan_end_date: '',
        loan_purpose: ''
      }
    },
    initFormUser() {
      this.form = {
        doc: '',
        pic: this.getUserProfile.employee.name,
        employee_id: this.getUserProfile.employee_id,
        plant: this.getUserProfile.employee.company,
        department: this.getUserProfile.employee.department,
        email: this.getUserProfile.employee.email,
        loan_purpose: ''
      }
    },
    initDropdown(val) {
      this.dropdownUnit()
      this.dropdownPlant()
      this.dropdownArchive(val)
      this.dropdownDepartment()
    },
    submit() {
      const a = false
      if (a) {
        this.docRules = [v => !!v || 'Document is required']
        this.picNameRules = [v => !!v || 'PIC Name is required']
        this.plantRules = [v => !!v || 'PIC Plant is required']
        this.departmentRules = [v => !!v || 'PIC Department is required']
        this.emailRules = [v => !!v || 'PIC Email is required']
        this.qtyRules = [v => !!v || 'Quantity is required']
        this.unitRules = [v => !!v || 'Unit is required']
        this.releaseDateRules = [v => !!v || 'Loan Start Date is required']
        this.reasonRules = [v => !!v || 'Reason is required']

        const self = this
        setTimeout(function() {
          if (self.$refs.entryForm.validate()) {
            if (self.isAdmin) {
              self.createNewForm()
            } else {
              self.createNewFormUser()
            }
          }
        })
      } else {
        alert('Cooming Soon')
      }
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        doc_id:
          this.getDocId.id === null
            ? Number(this.form.doc.id)
            : this.getDocId.id,
        // employee_id: this.getUserProfile.employee_id,
        // employee_nik: this.getUserProfile.employee.nik,
        // employee_name: this.getUserProfile.employee.name,
        employee_id: this.form.employee_id,
        employee_nik: this.form.employee_nik,
        employee_name: this.form.employee_name,
        pic_name: this.form.pic.name,
        plant_id: this.form.plant.id,
        plant_name: this.form.plant.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        email: this.form.email,
        qty: Number(this.form.qty),
        qty_uom_id: this.form.qty_uom.id,
        loan_start_date: this.form.loan_start_date,
        loan_end_date: this.form.loan_end_date,
        loan_purpose: this.form.loan_purpose
      }
      console.log(newForm)
      this.save(newForm)
    },
    save(form) {
      axios
        .post(`${this.eSanqua}archive_document/loan/save`, form)
        .then(res => {
          console.log(res.data.created_id)
          const createdID = res.data.created_id
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.form.pic = null
            setTimeout(() => {
              this.clear()
              this.showMsgDialog(
                'question',
                'Print Tanda Terima Peminjaman',
                true
              ).then(r => {
                if (r.isConfirmed) {
                  axios({
                    url: `${this.eSanqua}archive_document/loan/export_to_pdf/${createdID}`,
                    method: 'GET',
                    responseType: 'blob'
                  })
                    .then(resp => {
                      console.log(resp)
                      const url = window.URL.createObjectURL(
                        new Blob([resp.data])
                      )
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', 'Tanda_Terima_Arsip.pdf')
                      document.body.appendChild(link)
                      link.click()
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
              })
            }, 500)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              console.log(res)
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    createNewFormUser() {
      const newForm = {
        act: 'add',
        doc_id:
          this.getDocId.id === null
            ? Number(this.form.doc.id)
            : this.getDocId.id,
        employee_id: this.form.employee_id,
        plant_id: this.form.plant.id,
        plant_name: this.form.plant.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        email: this.form.email,
        loan_purpose: this.form.loan_purpose
      }
      console.log(newForm)
      this.saveUser(newForm)
    },
    saveUser(form) {
      axios
        .post(`${this.eSanqua}archive_document/self_service_loan/save`, form)
        .then(res => {
          console.log(res)

          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.clear()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    querySelections(v) {
      this.dropdownEmployee(v)
      this.loading = true
      setTimeout(() => {
        this.itemss = this.getDropdownEmployee
        this.loading = false
      }, 500)
    },
    cekDocStatus(event) {
      this.docStatus.code = ''
      this.docStatus.msg = ''
      axios
        .get(`${this.eSanqua}archive_document/status/${event.id}`)
        .then(res => {
          console.log(res)
          this.docStatus.code = res.data.status_code
          this.docStatus.msg = res.data.status_msg
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
